<template>
  <vf-navigation-sidebar
    :items-top="itemsTop"
    :items-bottom="itemsBottom"
    :link-user-section="ROUTING.accountSettings.url"
    :full-name="userFullName"
    :username="userUsername"
    :current-page="router?.currentRoute?.value?.name?.toString() || ''"
    :fixed="true"
    :internal-user="internalUser"
    :is-loading-user="isLoadingUser"
    :link-home="ROUTING.dashboard.url"
    :notif-count="notifCount || 0"
  />
</template>

<script setup lang="ts">
import useCardsData from "@/utils/cardsData";
import {
  translation,
  useBrandSelectionModal,
  usePermissions,
  useUserInfoOkta,
} from "@mercury/common";
import {
  VfNavigationSidebar,
  types as VfNavigationSidebarTypes,
} from "@mercury/common/VfNavigationSidebar";
import useGetUpdatesCount from "mercuryNotifications/useGetUpdatesCount";
import { computed } from "vue";
import { useRouter } from "vue-router";
import { ROUTING } from "../routing/routes";

const { cardsData } = useCardsData();

const { brandSelectionModalInfo } = useBrandSelectionModal();
const { translate } = translation.useTranslation();
const router = useRouter();
const { count: notifCount } = useGetUpdatesCount({
  // type: "all",
  // read: "unread",
  infiniteLoop: true,
});

const props = defineProps<{
  // alert: object;
}>();

//retrieve user permissions
const { permissions } = usePermissions();
const isLoadingUser = computed(() => !permissions.value);
const internalUser = computed(() => permissions.value?.internal);
const itemsTop = computed(() => {
  let itemsTopTemp = [];

  itemsTopTemp.push({
    id: ROUTING.dashboard.name,
    icon: "/assets/icons/navigation/home",
    label: translate(ROUTING.dashboard.labelKey),
    link: ROUTING.dashboard.url,
  });
  if (!internalUser.value) {
    if (permissions.value) {
      if (permissions.value.ecommerceExternal) {
        itemsTopTemp.push({
          id: ROUTING.brand.name,
          icon: "/assets/icons/navigation/bag",
          label: translate(ROUTING.brand.labelKey),
          link: ROUTING.brand.url,
        });
      }
      if (permissions.value.orderManagement) {
        itemsTopTemp.push({
          id: ROUTING.order.name,
          icon: "/assets/icons/navigation/edit",
          label: translate(ROUTING.order.labelKey),
          link: ROUTING.order.url,
        });
      }
      if (permissions.value.orderInquiry) {
        itemsTopTemp.push({
          id: ROUTING.inquiry.name,
          icon: "/assets/icons/navigation/truck",
          label: translate(ROUTING.inquiry.labelKey),
          link: ROUTING.inquiry.url,
        });
      }
      if (permissions.value.orderInquiryNew) {
        itemsTopTemp.push({
          id: ROUTING.orderHistory.name,
          icon: "/assets/icons/navigation/truck",
          label: translate(ROUTING.orderHistory.labelKey),
          link: ROUTING.orderHistory.url,
        });
      }
      if (permissions.value.returns) {
        itemsTopTemp.push({
          id: ROUTING.returns.name,
          icon: "/assets/icons/navigation/arrow-narrow-left",
          label: translate(ROUTING.returns.labelKey),
          link: ROUTING.returns.url,
        });
      }
      if (permissions.value.warranty) {
        itemsTopTemp.push({
          id: ROUTING.warranty.name,
          icon: "/assets/icons/navigation/annotation-alert",
          label: translate(ROUTING.warranty.labelKey),
          link: ROUTING.warranty.url,
        });
      }
      if (permissions.value.report) {
        itemsTopTemp.push({
          id: ROUTING.report.name,
          icon: "/assets/icons/navigation/pie-chart-04",
          label: translate(ROUTING.report.labelKey),
          link: ROUTING.report.url,
        });
      }
      if (
        permissions.value.document &&
        (permissions.value.documentInvoice ||
          permissions.value.documentCreditNote ||
          permissions.value.documentPayment)
      ) {
        itemsTopTemp.push({
          id: ROUTING.documents.name,
          icon: "/assets/icons/navigation/file",
          label: translate("sidebar.documents"),
          link: ROUTING.documents.url,
        });
      }
    }
  } else {
    for (let card of cardsData.value) {
      itemsTopTemp.push({
        id: card.id,
        icon: card.icon,
        label: translate(card.title),
        link: "",
        target: "",
        cta: card.onClick,
      });
    }
  }

  if (brandSelectionModalInfo?.value?.isLoading) {
    itemsTopTemp = itemsTopTemp.map((item) => ({
      ...item,
      disabled: true,
    }));
  }

  return itemsTopTemp;
});

const itemsBottom = computed<VfNavigationSidebarTypes.SidebarItem[]>(() => {
  let itemsBottomTemp: VfNavigationSidebarTypes.SidebarItem[] = [];

  if (permissions.value) {
    itemsBottomTemp = [];
    if (permissions.value.alerts || permissions.value.notifications) {
      itemsBottomTemp.push({
        id: ROUTING.updates.name,
        icon: "/assets/icons/navigation/bell",
        label: translate(ROUTING.updates.labelKey),
        link: ROUTING.updates.url,
        counter: notifCount.value,
      });
    }

    if (permissions.value.ticket) {
      itemsBottomTemp.push({
        id: ROUTING.tickets.name,
        icon: "/assets/icons/navigation/message-text-circle-02",
        label: translate(ROUTING.tickets.labelKey),
        link: ROUTING.tickets.url,
      });
    }

    if (permissions.value.faq) {
      itemsBottomTemp.push({
        id: ROUTING.help.name,
        icon: "/assets/icons/navigation/help",
        label: translate(ROUTING.help.labelKey),
        link: ROUTING.help.url,
      });
    }
  }

  return itemsBottomTemp;
});

const { userInfoOkta } = useUserInfoOkta();
const userFullName = computed(() => {
  if (permissions?.value?.fullname && permissions?.value?.fullname !== "null null") {
    return permissions?.value?.fullname;
  }
  return "";
});
const userUsername = computed(() => userInfoOkta?.value?.preferred_username || "");
</script>
