<template>
  <div
    v-if="hasError"
    class="py-9xl px-2xl flex items-center b-1px b-solid b-gray-300 radius-md mt-3xl lt-lg:mx-xl mx-4xl w-full"
  >
    <vf-empty-state
      size="medium"
      class="mx-auto!"
      :icon="emptyIcon"
      :title="translate('dashboard.generic_error') || 'Something went wrong'"
    />
  </div>
  <slot v-else></slot>
</template>

<script setup lang="ts">
import { sendErrorAnalytics, translation } from "@mercury/common";
import { VfEmptyState } from "@mercury/common/VfEmptyState";
import { onErrorCaptured, ref } from "vue";
const { translate } = translation.useTranslation();

const emptyIcon = window.globalEnvVariables?.SHELL_ICONS_URL + "alert-triangle";
const hasError = ref(false);

onErrorCaptured((error) => {
  console.log(error);
  console.error("ShellErrorBoundary", { error });

  //analytics
  sendErrorAnalytics({
    asset: "FE",
    description: "Error on ShellErrorBoundary",
    techInfo: error,
  });

  hasError.value = true;
  return false;
});
</script>
