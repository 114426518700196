<template>
  <div class="font-mulish flex">
    <shell-error-boundary>
      <vf-loading
        :is-loading="showLoader && !showErrorLabel"
        :ignore-labels="true"
        class="mt-6xl mx-auto"
      >
        <shell-navigation
          v-if="authState?.isAuthenticated && showNavigation && userInfoOkta && !showErrorLabel"
        />
        <div
          class="w-full min-h-100vh"
          :class="{
            'max-w-[calc(100vw_-_72px)] print:max-100vw':
              authState?.isAuthenticated && showNavigation && userInfoOkta && !showErrorLabel,
            'max-w-100vw!': isMobileOrTabletPortraitHeaderVersion,
            'mt-4rem': isMobileOrTabletPortraitHeaderVersion,
          }"
        >
          <vf-alert
            v-if="navigationOnBehalf?.id"
            class="md:landscape:sticky! xl:sticky! top-0 z-30 text-sm bold print:hidden!"
            :show="true"
            header=""
            :body="
              translate('on_behalf.popup_navigation').replace(
                '{{value}}',
                `${navigationOnBehalf?.id} ${navigationOnBehalf?.name}`,
              )
            "
            :cta-label="navigationOnBehalf?.backLabel ?? translate('on_behalf.cta_back')"
            :cta-action="navigationOnBehalf?.backCTA ?? returnToClientSearch"
            type="notification"
            :floating="false"
            :banner="false"
            :show-close="true"
            :close-action="refreshPage"
            :dropdown-menu="onBehalfMenuOptions"
            :default-dropdown-menu="defaultOnBehalfDropdownItem"
            :disable-dropdown-menu="navigationOnBehalf?.disableDropdownNavigation"
          />

          <div
            v-if="showErrorLabel"
            class="py-9xl px-2xl flex items-center b-1px b-solid b-gray-300 radius-md mt-3xl lt-lg:mx-xl mx-4xl"
          >
            <vf-empty-state
              size="medium"
              class="mx-auto!"
              :icon="emptyIcon"
              :title="showErrorLabel"
              :buttons="
                isUserAuthenticated
                  ? [
                      {
                        label: translate('account.logout') || 'Log out',
                        cta: logout,
                        variant: 'primary',
                        size: 'medium',
                      },
                    ]
                  : []
              "
            />
          </div>
          <router-view v-else />
        </div>

        <ticket-modal
          max-width="max-w-62.5rem"
          v-bind="ticketModalInfo"
          :close-modal="
            () => {
              ticketModalInfo.show = false;
              if (ticketModalInfo.closeModal) {
                ticketModalInfo.closeModal();
              }
            }
          "
        />
        <cookie-policy-modal
          v-if="cookiePolicyModalInfo?.show"
          :show="cookiePolicyModalInfo?.show"
          :close-modal="
            () => {
              cookiePolicyModalInfo.show = false;
            }
          "
        />
        <change-account-modal
          v-if="changeAccountModalInfo?.show"
          :show="changeAccountModalInfo?.show"
          :close-modal="
            () => {
              console.log('close modal change account');
              changeAccountModalInfo.show = false;
            }
          "
        />
      </vf-loading>
    </shell-error-boundary>
  </div>
</template>

<script setup lang="ts">
import { ROUTING } from "@/routing/routes";

import { CookiePolicyModalInfo, openCookiePolicyModal } from "@/utils/utilsCookiePolicyModal";
import ChangeAccountModal from "@components/ChangeAccountModal.vue";
import CookiePolicyModal from "@components/CookiePolicyModal.vue";
import ShellErrorBoundary from "@components/ShellErrorBoundary.vue";
import ShellNavigation from "@components/ShellNavigation.vue";
import {
  ChangeAccountModalInfo,
  TicketModalInfo,
  analyticsOptin,
  canUserAcceptTerms,
  cookieUtility,
  eventProvider,
  sendErrorAnalytics,
  translation,
  useBreakpoint,
  usePermissions,
  useSelectedSoldTo,
  useSoldToList,
  useUserInfoOkta,
  useUserInfoPermissions,
} from "@mercury/common";
import { VfAlert } from "@mercury/common/VfAlert";
import { VfEmptyState } from "@mercury/common/VfEmptyState";
import { VfLoading } from "@mercury/common/VfLoading";
import { UserClaims } from "@okta/okta-auth-js";
import { useAuth } from "@okta/okta-vue";
import { changePasswordAfterLogin, getPasswordLastUpdated, logout } from "@utils/auth";
import { isUserInternal } from "@utils/userUtils";
import TicketModal from "mercuryFaqsTickets/TicketModal";
import { deleteNotification, getUpdates } from "mercuryNotifications/NotificationService";
import {
  ComponentCustomProperties,
  Ref,
  computed,
  getCurrentInstance,
  onMounted,
  onUnmounted,
  ref,
  toRaw,
  watch,
} from "vue";
import { useRoute, useRouter } from "vue-router";
import { CustomerGeneralities } from "./service/UserModel";
import {
  getCustomerInfo,
  getLegacyUrl,
  getSoldTos,
  getUserPermissions,
  mapUserPermissions,
} from "./service/UserService";
import {
  getIsSoldToSelected,
  selectLastSelectedSoldTo,
  setLastSelectedSoldTo,
} from "./utils/soldToUtils";

const emptyIcon = window.globalEnvVariables?.SHELL_ICONS_URL + "alert-triangle";
const {
  translate,
  lng,
  errorLabel: errorRetrievingLabels,
} = translation.useTranslation({ updateLanguageDynamically: true });
const { isMobileScreen, isTabletScreen, isPortrait } = useBreakpoint();
const route = useRoute();
const router = useRouter();
const ticketModalInfo = ref<TicketModalInfo>({ show: false });
const cookiePolicyModalInfo = ref<CookiePolicyModalInfo>({ show: false });
const changeAccountModalInfo = ref<ChangeAccountModalInfo>({ show: false });
const { permissions, permissionsOnBehalf } = usePermissions();
const navigationOnBehalf = ref({});
const errorPermissions = ref(false);
const errorSoldTos = ref(false);
const showLoader = ref(false);
const deleteNotificationAfterForgotPasswordFlow = ref(false);
const defaultOnBehalfDropdownItem = ref(undefined);

const isMobileOrTabletPortraitHeaderVersion = computed(
  () => isMobileScreen.value || (isTabletScreen.value && isPortrait.value),
);

//handle MFE events
const eventProviderInstance = eventProvider.istantiateEventProvider();
const { userInfoOkta } = useUserInfoOkta(eventProviderInstance);
const { userInfoPermissions } = useUserInfoPermissions(eventProviderInstance);
const showErrorSoldTos = computed(
  () =>
    errorSoldTos.value && userInfoPermissions.value && !isUserInternal(userInfoPermissions.value),
);
const showErrorNoBrand = computed(
  () =>
    userInfoPermissions.value &&
    isUserInternal(userInfoPermissions.value) &&
    (!userInfoPermissions.value.activeBrands ||
      userInfoPermissions.value.activeBrands.length === 0),
);
const showErrorLabel = computed(() => {
  if (showErrorSoldTos.value) {
    return translate("dashboard.error_accounts");
  }
  if (showErrorNoBrand.value) {
    return translate("dashboard.error_brands");
  }
  if (errorPermissions.value) {
    return translate("dashboard.error_permissions") || "Error retrieving user permissions";
  }
  if (errorRetrievingLabels.value) {
    return "Error retrieving labels";
  }

  return "";
});

const unsubscribeLogout = eventProviderInstance?.subscribe(
  eventProvider.EventChannel.logout,
  () => {
    logout(eventProviderInstance);
  },
);
const auth = useAuth();
const unsubscribeChangePasswordAfterLogin = eventProviderInstance?.subscribe(
  eventProvider.EventChannel.changePasswordPostLogin,
  ({ password }: { password: string }) => {
    changePasswordAfterLogin(
      password,
      selectedSoldTo?.value?.customerId,
      auth,
      eventProviderInstance,
    );
  },
);
const unsubscribeGetPasswordLastUpdated = eventProviderInstance?.subscribe(
  eventProvider.EventChannel.getPasswordLastUpdated,
  () => {
    getPasswordLastUpdated(auth, eventProviderInstance);
  },
);

const unsubscribeTicketModal = eventProviderInstance?.subscribe(
  eventProvider.EventChannel.ticketModal,
  (ticketModalInfoEvent: TicketModalInfo) => {
    //subscribe for open ticket modal requests
    const ticketModalInfoTemp = {
      show: true,
      showClose: true,
      title: translate("create_ticket.title"),
      description: translate("create_ticket.description"),
      //initialDataForm { category: 0, subcategory: 1 }
      ...ticketModalInfoEvent,
    };

    if (ticketModalInfoEvent.initialDataId) {
      let dataFromEnvVar = {};

      try {
        dataFromEnvVar = JSON.parse(
          window.globalEnvVariables?.[ticketModalInfoEvent.initialDataId] || "{}",
        );
      } catch (error) {
        console.error("Error getting env variable to open ticket modal", {
          initialDataId: ticketModalInfoEvent.initialDataId,
        });
      }

      // const category = dataFromEnvVar?.split(";")?.[0]?.replace("category:", "");
      // const subcategory = dataFromEnvVar?.split(";")?.[1]?.replace("subcategory:", "");
      // ticketModalInfoTemp.initialDataForm = {
      //   category: +category,
      //   subcategory: +subcategory,
      // };
      ticketModalInfoTemp.initialDataForm = dataFromEnvVar;
      delete ticketModalInfoTemp.initialDataId;
    }

    // console.log("ticketModalInfoEvent", { ticketModalInfoEvent, ticketModalInfoTemp });

    ticketModalInfo.value = ticketModalInfoTemp;
  },
  { self: true },
);

const unsubscribeBrandSelectionModal = eventProviderInstance?.subscribe(
  eventProvider.EventChannel.brandSelectionModal,
  (brandSelectionModalInfoEvent: eventProvider.IBrandSelectionModal) => {
    //if isLoaded = true --> ignore the event
    if (!brandSelectionModalInfoEvent?.isLoaded) {
      if (brandSelectionModalInfoEvent?.legacyId && !brandSelectionModalInfoEvent?.isLoading) {
        //do not show popup if the user has already selected the brand or if the user has only one brand

        //set is loading to true
        setTimeout(() => {
          eventProviderInstance?.emit(eventProvider.EventChannel.brandSelectionModal, {
            ...brandSelectionModalInfoEvent,
            isLoading: true,
          });

          const finallyCallback = (error = false) => {
            //set is loading to false
            eventProviderInstance?.emit(eventProvider.EventChannel.brandSelectionModal, {
              ...brandSelectionModalInfoEvent,
              isLoading: false,
              isLoaded: true,
              isError: error,
            });
          };

          getLegacyUrl({
            brandId: brandSelectionModalInfoEvent.brand || "",
            legacyId: brandSelectionModalInfoEvent.legacyId || "",
            language: lng.value,
            redirect: true,
          })
            .then(() => {
              finallyCallback(false);
            })
            .catch(() => {
              finallyCallback(true);
            });
        }, 0);
      }
      // else {
      //   brandSelectionModalInfo.value = brandSelectionModalInfoEvent;
      // }
    }
  },
  { self: true },
);

const unsubscribeCookiePolicyModal = eventProviderInstance?.subscribe(
  eventProvider.EventChannel.cookiePolicyModal,
  (cookiePolicyModalInfoEvent: CookiePolicyModalInfo) => {
    cookiePolicyModalInfo.value = cookiePolicyModalInfoEvent;
  },
  { self: true },
);

const unsubscribeChangeAccountModal = eventProviderInstance?.subscribe(
  eventProvider.EventChannel.changeAccountModal,
  (changeAccountModalInfoEvent: CookiePolicyModalInfo) => {
    changeAccountModalInfo.value = changeAccountModalInfoEvent;
  },
  { self: true },
);

const unsubscribeNavigationOnBehalf = eventProviderInstance?.subscribe(
  eventProvider.EventChannel.navigationOnBehalf,
  (navigationOnBehalfInfoEvent: any) => {
    navigationOnBehalf.value = navigationOnBehalfInfoEvent;
  },
  { self: true },
);

const unsubscribeDeleteNotification = eventProviderInstance?.subscribe(
  eventProvider.EventChannel.deleteNotification,
  (deleteNotificationEvent: any) => {
    deleteNotification(deleteNotificationEvent);
  },
  { self: true },
);

const unsubscribeDeleteNotificationAfterForgotPassword = eventProviderInstance?.subscribe(
  eventProvider.EventChannel.deleteNotificationAfterForgotPassword,
  (deleteNotificationEvent: any) => {
    if (deleteNotificationEvent.typeUpdate === "expiring_password")
      deleteNotificationAfterForgotPasswordFlow.value = true;
  },
  { self: true },
);

const unsubscribeGetUpdates = eventProviderInstance?.subscribe(
  eventProvider.EventChannel.getUpdates,
  (getUpdatesEvent: any) => {
    if (getUpdatesEvent.request) {
      let updatesResponse = { response: undefined, updates: [] };
      const params = getUpdatesEvent.params;

      //set loading = true
      eventProviderInstance?.emit(eventProvider.EventChannel.getUpdates, {
        ...updatesResponse,
        params: params,
        isLoading: true,
      });
      getUpdates(getUpdatesEvent?.params)
        .then((res) => {
          updatesResponse = res;
        })
        .finally(() => {
          eventProviderInstance?.emit(eventProvider.EventChannel.getUpdates, {
            ...updatesResponse,
            params: params,
            isLoading: false,
          });
        });
    }
  },
  { self: true },
);

const isValidUrlOnBehal = (path: string) => {
  const regexUrlsOnBehalf = [
    "^/account/stores/\\d+/\\d+/\\d+$",
    "^/account/stores$",
    "^/account/company-info$",
    "^/account/documents$",
    "^/account/contacts$",
    "^/account/terms-conditions$",
    "^/reports$",
    "^/reports/[^/]+(_[a-zA-Z0-9_]+)?$",
    "^/warranty$",
    "^/returns$",
    "^/ticket/history$",
    "^/faqs$",
    "^/faqs(/topic/([a-zA-Z-]+|\\d+))?(/faq/([a-zA-Z-]+|\\d+))?$",
    "^/faqs/user-guide$",
    `^${ROUTING.updates.url}$`,
    "^/brands$",
    "^/brands/\\d+$",
    "^/brands/shop$",
    "^/brands/shop/\\d+$",
    "^/inquiry$",
    "^/orders$",
    "^/orders/\\d+$",
  ];
  for (let rule of regexUrlsOnBehalf) {
    let regex = new RegExp(rule);
    if (regex.test(path)) {
      return true;
    }
  }
  return false;
};

const isPublicPath = (path: string) => {
  let regex = new RegExp("^/public(?:/.*)?$");
  if (regex.test(path)) {
    return true;
  }
  return false;
};

router.beforeEach((to, from, next) => {
  if (navigationOnBehalf.value?.id && !isValidUrlOnBehal(to.path)) {
    navigationOnBehalf.value = {};
    next({ path: to.path, replace: true });
  } else {
    to.meta.previousRoute = from;
    next();
  }
});

const refreshPage = () => {
  navigationOnBehalf.value = {};
  window.location.reload();
};

const returnToClientSearch = () => {
  navigationOnBehalf.value = {};
  router?.push("/customer-search");
};

const cleanOnBehalfNavigation = () => {
  eventProviderInstance?.emit(eventProvider.EventChannel.customerInfo, null);
  eventProviderInstance?.emit(eventProvider.EventChannel.brands, null);
};

const showNavigation = computed(() => {
  //do not show sidebar navigation in prelogin pages
  if (
    [
      ROUTING.login.name,
      ROUTING.forgotPassword.name,
      ROUTING.maintenance.name,
      ROUTING.waiting.name,
      ROUTING.acceptTermsConditions.name,
      ROUTING.soldTo.name,
      ROUTING.publicVideo.name,
      ROUTING.publicTermsConditionsPage.name,
    ].includes(route.name)
  ) {
    return false;
  } else {
    return true;
  }
});

const isUserAuthenticated = ref(false);

onMounted(() => {
  //handle authentication for MFE remotes
  const componentData = getCurrentInstance()?.data;
  watch(
    () => componentData?.authState?.isAuthenticated,
    (isAuthenticated) => {
      isUserAuthenticated.value = isAuthenticated;
      if (isAuthenticated) {
        const authState = toRaw(componentData?.authState) as ComponentCustomProperties["authState"];

        if (authState) {
          eventProviderInstance?.emit(
            eventProvider.EventChannel.accessToken,
            authState?.accessToken?.accessToken,
          );
        }

        const auth = useAuth();
        if (auth) {
          auth
            .getUser()
            .then((userInfo: UserClaims) => {
              if (userInfo) {
                eventProviderInstance?.emit(eventProvider.EventChannel.userInfoOkta, userInfo);
              }
              if (typeof dtrum !== "undefined" && dtrum && userInfo?.email) {
                dtrum.identifyUser(userInfo?.email);
              }
            })
            .catch((err) => {
              console.error("getCustomerInfo error", { err });

              //analytics
              sendErrorAnalytics({
                type: "Okta",
                description: "Error getting user info",
                techInfo: err,
              });

              if (err.xhr.status === 401) {
                //if user is unauthorized --> logout
                logout(eventProviderInstance);
              }
            });
        }

        //get sold-tos
        getSoldTos().catch(() => {
          errorSoldTos.value = true;
        });
        //get user permissions and language
        getUserPermissions().catch(() => {
          errorPermissions.value = true;
        });
      } else {
        //clear data
        eventProviderInstance?.emit(eventProvider.EventChannel.accessToken, "");
        eventProviderInstance?.emit(eventProvider.EventChannel.userInfoOkta, null);
        eventProviderInstance?.emit(eventProvider.EventChannel.customerInfo, null);
        eventProviderInstance?.emit(eventProvider.EventChannel.userInfoPermissions, null);
        //clean user permissions and brands
        mapUserPermissions(undefined, "");
      }
    },
    { immediate: true },
  );

  //handle sold-to selection redirect
  watch(
    [() => route.name, soldToList, selectedSoldTo, () => componentData?.authState?.isAuthenticated],
    (params) => {
      const [routeName, soldToList, selectedSoldTo, isAuthenticated] = params;
      const isSoldToSelected = getIsSoldToSelected();
      console.log("isAuthenticated", { isAuthenticated });

      //if user has to select the sold-to, redirect to the sold-to page
      if (
        soldToList &&
        soldToList?.length > 0 &&
        !selectedSoldTo &&
        ![ROUTING.soldTo.name, ROUTING.waiting.name].includes(routeName as string) &&
        isAuthenticated
      ) {
        console.log("Handle sold-to selection", { soldToList, selectedSoldTo, routeName });

        if (isSoldToSelected) {
          selectLastSelectedSoldTo(soldToList);
        } else if (soldToList.length === 1) {
          const firstSoldTo = soldToList[0];
          setLastSelectedSoldTo(firstSoldTo.customerId || "");
        } else {
          router.replace(ROUTING.soldTo.url);
        }

        showLoader.value = false;
      } else if (!isSoldToSelected && isAuthenticated && !soldToList) {
        showLoader.value = true;
      } else {
        showLoader.value = false;
      }
    },
  );
});

const selectedSoldTo = useSelectedSoldTo();
const soldToList = useSoldToList() as Ref<CustomerGeneralities[] | undefined>;
const emptySoldTos = computed(
  () =>
    soldToList.value?.length === 0 &&
    userInfoPermissions.value &&
    !isUserInternal(userInfoPermissions.value),
);

//go to waiting page if external user has not associated sold-tos
watch(emptySoldTos, (emptySoldTos) => {
  if (emptySoldTos) {
    router.push(ROUTING.waiting.url);
  }
});

// Delete reminder expiring password after forgot password flow
watch(
  [() => deleteNotificationAfterForgotPasswordFlow.value, selectedSoldTo, userInfoOkta],
  (params) => {
    const [deleteNotification, selectedSoldTo, userInfoOkta] = params;
    if (deleteNotification && selectedSoldTo && userInfoOkta) {
      // delete expiring password
      eventProviderInstance.emit(eventProvider.EventChannel.deleteNotification, {
        typeUpdate: "expiring_password",
        soldToId: selectedSoldTo?.customerId,
      });
      //update last time password change
      eventProviderInstance?.emit(eventProvider.EventChannel.getPasswordLastUpdated);
      eventProviderInstance?.emit(eventProvider.EventChannel.getUpdates, {
        params: {
          pagination: { pageNumber: 0, pageSize: 1 },
          soldTo: selectedSoldTo?.customerId,
          user: userInfoOkta?.email,
          updateType: "expiring_password",
        },
        request: true,
      });

      deleteNotificationAfterForgotPasswordFlow.value = false;
    }
  },
);

//handle standard Terms and Conditions redirect
watch([() => route.name, selectedSoldTo, () => route.path, permissions], (params) => {
  const [routeName, selectedSoldTo, routePath, _] = params;
  if (
    selectedSoldTo &&
    (!selectedSoldTo?.termsAndConditionsAcceptedBy ||
      !selectedSoldTo?.termsAndConditionsTimestamp) &&
    ![ROUTING.waiting.name, ROUTING.acceptTermsConditions.name, ROUTING.soldTo.name].includes(
      routeName as string,
    ) &&
    !permissions.value?.internal &&
    //permissions?.value?.termsAndConditions
    canUserAcceptTerms(selectedSoldTo.customerId) &&
    routePath &&
    !isPublicPath(routePath)
  ) {
    router.replace(
      ROUTING.acceptTermsConditions.url.replace(":soldToId", selectedSoldTo.customerId),
    );
  }
});

//store user permissions and brands when the user has selected his sold-to
watch([userInfoPermissions, selectedSoldTo], (params) => {
  const [userInfoPermissions, selectedSoldTo] = params;

  if (userInfoPermissions && (selectedSoldTo?.customerId || isUserInternal(userInfoPermissions))) {
    //when user permissions are downloaded and sold to is selected --> map FE user permissions
    mapUserPermissions(userInfoPermissions, selectedSoldTo?.customerId);
  }
});

//handle download customer data when the user has selected his sold-to
watch(selectedSoldTo, (selectedSoldTo) => {
  if (selectedSoldTo) {
    //get customer info
    getCustomerInfo({ currentCustomerId: selectedSoldTo.customerId });
  }
});

//handle download customer data when navigate on-behalf
watch(navigationOnBehalf, (navigationOnBehalf) => {
  cleanOnBehalfNavigation();
  if (navigationOnBehalf?.id) {
    getCustomerInfo({
      onBehalf: true,
      customerOnBehalfId: navigationOnBehalf?.id,
      currentCustomerId: navigationOnBehalf?.id,
    });
  } else {
    getUserPermissions().catch(() => {
      errorPermissions.value = true;
    });
  }
});

const showCookiePolicyModal = ref<boolean>(!cookieUtility.isCookiePolicyValid());
//handle show cookie policy modal
watch([() => route.name, permissions], (params) => {
  const [_, permissions] = params;

  //if the user is an external user --> I know if the user is an external user just after eventual sold-to selection
  //if the user has never accepted cookies
  //if the route is an authenticated route
  //if the route isn't a route that hides the cookie modal
  if (
    showCookiePolicyModal.value &&
    permissions &&
    !permissions?.internal &&
    route?.meta?.requiresAuth &&
    !route?.meta?.hideCookieModal
  ) {
    openCookiePolicyModal({
      show: true,
    });
    showCookiePolicyModal.value = false;
  } else if (route?.meta?.hideCookieModal && cookiePolicyModalInfo.value.show) {
    openCookiePolicyModal({
      show: false,
    });
    showCookiePolicyModal.value = !cookieUtility.isCookiePolicyValid();
  }
});

//scroll on top at every page change
watch(route, () => {
  window.scrollTo(0, 0);
});

onUnmounted(() => {
  //unsubscribe all listeners
  if (unsubscribeLogout) {
    unsubscribeLogout();
  }
  if (unsubscribeChangePasswordAfterLogin) {
    unsubscribeChangePasswordAfterLogin();
  }
  if (unsubscribeGetPasswordLastUpdated) {
    unsubscribeGetPasswordLastUpdated();
  }
  if (unsubscribeTicketModal) {
    unsubscribeTicketModal();
  }
  if (unsubscribeBrandSelectionModal) {
    unsubscribeBrandSelectionModal();
  }
  if (unsubscribeCookiePolicyModal) {
    unsubscribeCookiePolicyModal();
  }
  if (unsubscribeChangeAccountModal) {
    unsubscribeChangeAccountModal();
  }
  if (unsubscribeNavigationOnBehalf) {
    unsubscribeNavigationOnBehalf();
  }
  if (unsubscribeDeleteNotification) {
    unsubscribeDeleteNotification();
  }
  if (unsubscribeDeleteNotificationAfterForgotPassword) {
    unsubscribeDeleteNotificationAfterForgotPassword();
  }
  if (unsubscribeGetUpdates) {
    unsubscribeGetUpdates();
  }
});

//initialize partytown
// loadPartytown();

watch(
  permissions,
  (permissions) => {
    if (permissions) {
      //check if the user can be tracked
      analyticsOptin();
    }
  },
  { immediate: true },
);

// DROPDOWN MENU ITEMS FOR VF-ALERT
const onBehalfRedirect = (path: string) => {
  /*eventProviderInstance.emit(eventProvider.EventChannel.navigationOnBehalf, {
    id: client?.nodeId,
    name: client?.nodeDesc,
    ...client,
  });
  eventProviderInstance.emit(eventProvider.EventChannel.permissions, {
    permissions: toRaw(permissions.value),
    permissionsOnBehalf: client?.permissions,
    customerOnBehalf: client?.nodeId,
  })*/
  router?.push(path);
};

const onBehalfMenuOptions = computed(() => {
  let menuOptions = [];
  if (permissionsOnBehalf?.value?.companyInfo) {
    menuOptions.push({
      id: "/account/company-info",
      label: translate("on_behalf.select_company_info"),
      cta: () => onBehalfRedirect("/account/company-info"),
    });
  }
  if (permissionsOnBehalf?.value?.companyInfo) {
    menuOptions.push({
      id: "/account/stores",
      label: translate("on_behalf.select_shops"),
      cta: () => onBehalfRedirect("/account/stores"),
    });
  }
  if (
    permissionsOnBehalf?.value?.document &&
    (permissionsOnBehalf?.value?.documentInvoice ||
      permissionsOnBehalf?.value?.documentPayment ||
      permissionsOnBehalf?.value?.documentCreditNote)
  ) {
    menuOptions.push({
      id: "/account/documents",
      label: translate("on_behalf.select_document"),
      cta: () => onBehalfRedirect("/account/documents"),
    });
  }
  if (permissionsOnBehalf?.value?.ticket) {
    menuOptions.push({
      id: "/ticket/history",
      label: translate("on_behalf.select_ticket"),
      cta: () => onBehalfRedirect("/ticket/history"),
    });
  }
  if (permissionsOnBehalf?.value?.ecommerceExternal) {
    menuOptions.push({
      id: "/brands",
      label: translate("on_behalf.select_b2b"),
      cta: () => onBehalfRedirect("/brands"),
    });
  }
  if (permissionsOnBehalf?.value?.orderInquiry) {
    menuOptions.push({
      id: "/inquiry",
      label: translate("on_behalf.select_order"),
      cta: () => onBehalfRedirect("/inquiry"),
    });
  }
  if (permissionsOnBehalf?.value?.orderInquiryNew) {
    menuOptions.push({
      id: "/orderHistory",
      label: translate("on_behalf.select_order"),
      cta: () => onBehalfRedirect("/orders"),
    });
  }
  if (permissionsOnBehalf?.value?.returns) {
    menuOptions.push({
      id: "/returns",
      label: translate("on_behalf.select_return"),
      cta: () => onBehalfRedirect("/returns"),
    });
  }
  if (permissionsOnBehalf?.value?.warranty) {
    menuOptions.push({
      id: "/warranty",
      label: translate("on_behalf.select_warranty"),
      cta: () => onBehalfRedirect("/warranty"),
    });
  }
  if (permissionsOnBehalf?.value?.report) {
    menuOptions.push({
      id: "/reports",
      label: translate("on_behalf.select_report"),
      cta: () => onBehalfRedirect("/reports"),
    });
  }
  if (permissionsOnBehalf?.value?.contacts) {
    menuOptions.push({
      id: "/account/contacts",
      label: translate("on_behalf.select_contacts"),
      cta: () => onBehalfRedirect("/account/contacts"),
    });
  }
  if (permissionsOnBehalf?.value?.faq) {
    menuOptions.push({
      id: "/faqs",
      label: translate("on_behalf.select_faq"),
      cta: () => onBehalfRedirect("/faqs"),
    });
  }
  if (permissionsOnBehalf?.value?.dataPrivacy || permissionsOnBehalf.value?.termsAndConditions) {
    menuOptions.push({
      id: "/account/terms-conditions",
      label: translate("on_behalf.select_t&c"),
      cta: () => onBehalfRedirect("/account/terms-conditions"),
    });
  }
  if (
    (permissionsOnBehalf?.value?.notifications || permissionsOnBehalf?.value?.alerts) &&
    !window.globalEnvVariables?.HIDE_UPDATES
  ) {
    menuOptions.push({
      id: "/updates",
      label: translate("on_behalf.select_updates"),
      cta: () => onBehalfRedirect("/updates"),
    });
  }

  menuOptions.sort((a, b) => a.label?.toLowerCase()?.localeCompare(b.label?.toLowerCase()));
  return menuOptions;
});

watch(
  [() => route.path, () => navigationOnBehalf?.value?.id, () => onBehalfMenuOptions?.value],
  (param) => {
    const [routePath, navigationOnBehalfId, onBehalfMenuOptions] = param;
    defaultOnBehalfDropdownItem.value = undefined;
    if (navigationOnBehalfId && routePath && onBehalfMenuOptions) {
      defaultOnBehalfDropdownItem.value = onBehalfMenuOptions.find((el) => el.id === routePath);
    }
  },
);
</script>
<style>
body {
  overflow: auto;
}
body:has(.modal-open) {
  overflow: hidden !important;
}
</style>
