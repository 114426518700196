<template>
  <div>
    <vf-modal
      name="change-account"
      :open="show"
      class="flex! flex-col! gap-0! mx-auto! my-auto! justify-center! h-100vh!"
      class-popup="max-h-328px! overflow-y-auto!"
      :show-close="true"
      :title="translate('account.change_account')"
      :disable-click-trigger-self="false"
      :full="true"
      :remove-padding="true"
      @close="closeModal"
    >
      <template #description>
        <p v-dompurify-html="description.toString()" class="text-sm regular c-gray-600" />
      </template>
      <template #content>
        <div class="pb-3xl">
          <button
            v-for="soldTo in filteredSoldTos"
            :key="soldTo.customerId"
            class="py-lg text-lg semibold flex items-center justify-between gap-xl w-full b-0 bg-transparent cursor-pointer text-gray-900 hover:bg-gray-50 rounded-10px px-3xl text-left"
            :aria-label="soldTo.legalName"
            @click="selectStore(soldTo)"
          >
            <span>{{ [soldTo.legalName, soldTo.customerId].filter((a) => !!a).join(" - ") }}</span>
            <span><vf-icon :name="chevronIcon" size="md" color="gray-500" /></span>
          </button></div
      ></template>
    </vf-modal>
  </div>
</template>
<script setup lang="ts">
import { router } from "@/main";
import { ROUTING } from "@/routing/routes";
import { CustomerGeneralities } from "@/service/UserModel";
import { setLastSelectedSoldTo } from "@/utils/soldToUtils";
import {
  canUserAcceptTerms,
  sendEventAnalytics,
  translation,
  usePermissions,
  useSelectedSoldTo,
  useSoldToList,
} from "@mercury/common";
import { VfIcon } from "@mercury/common/VfIcon";
import { VfModal } from "@mercury/common/VfModal";
import { Ref, computed, ref, watch } from "vue";
import { buildVueDompurifyHTMLDirective } from "vue-dompurify-html";
const props = withDefaults(
  defineProps<{
    show: boolean;
    closeModal: () => void;
  }>(),
  { closeModal: () => {} },
);

const vDompurifyHtml = buildVueDompurifyHTMLDirective();
const { translate } = translation.useTranslation();
const { permissions } = usePermissions();
const chevronIcon = window.globalEnvVariables?.SHELL_ICONS_URL + "chevron-right";
const soldToList = useSoldToList() as Ref<CustomerGeneralities[] | undefined>;
const selectedSoldTo = useSelectedSoldTo();
const currentSoldTo = ref<CustomerGeneralities>();
const description = computed(() =>
  currentSoldTo.value
    ? translate("change_account.logged_account")?.replace(
        "{{soldto}}",
        "<b>" +
          [currentSoldTo.value?.legalName, currentSoldTo.value?.customerId]
            .filter((a) => !!a)
            .join(" - ") +
          "</b>",
      )
    : "",
);

watch(
  () => !!selectedSoldTo,
  (_) => {
    if (selectedSoldTo.value) {
      currentSoldTo.value = selectedSoldTo.value;
    }
  },
  { immediate: true },
);

// const route = useRoute();
// watchEffect(() => {
//   if (route.name !== ROUTING.accountSettings.name && props.closeModal) {
//     props.closeModal();
//   }
// });

const filteredSoldTos = computed(() => {
  const filteredTemp: CustomerGeneralities[] = [];
  soldToList?.value?.forEach((soldTo: CustomerGeneralities) => {
    if (soldTo.customerId !== currentSoldTo.value?.customerId) {
      filteredTemp.push(soldTo);
    }
  });
  return filteredTemp;
});

const selectStore = (soldTo: CustomerGeneralities) => {
  if (soldTo) {
    //analytics
    sendEventAnalytics({
      id: "soldToSwitch",
      soldToId: soldTo.customerId,
      soldToLegalName: soldTo.legalName,
    });

    if (
      (!soldTo.termsAndConditionsAcceptedBy || !soldTo.termsAndConditionsTimestamp) &&
      !permissions.value?.internal &&
      canUserAcceptTerms(soldTo.customerId)
    ) {
      //go to accept terms and conditions
      //sold-to selection will be applied only if the user accept them
      router.push(ROUTING.acceptTermsConditions.url.replace(":soldToId", soldTo.customerId));
      props.closeModal();
    } else {
      //select sold-to
      setLastSelectedSoldTo(soldTo.customerId, ROUTING.dashboard.url);
      props.closeModal();
    }
  }
  console.log("selectStore", soldTo);
};
</script>
