import App from "@/App.vue";
import "@mercury/common/style";
import { OktaAuth } from "@okta/okta-auth-js";
import OktaVue, { OktaVueOptions, navigationGuard } from "@okta/okta-vue";
import { oktaConfig } from "@utils/auth";
import { createPinia } from "pinia";
import { registerSW } from "virtual:pwa-register";
import "virtual:uno.css";
import { createApp } from "vue";
import { createRouter, createWebHistory } from "vue-router";
import { ROUTING, maintenanceRoute, routes } from "./routing/routes";
import "./style/main.scss";

registerSW({
  immediate: true,
  onRegisterError(error) {},
});

//prevent Okta CORS error in localhost
if (import.meta.env.MODE === "development") {
  const { fetch: originalFetch } = window;
  window.fetch = (...args) => {
    let [resource, config] = args;
    // console.log("resource", { resource, config, args });
    // POINT TO DEV
    const oktaUrl = "https://account-vfportal-emea-dev.vfc.com";
    // POINT TO QA
    //const oktaUrl = "https://account-vfportal-qa.vfc.com";
    if (resource?.startsWith(oktaUrl)) {
      resource = resource.replace(oktaUrl, "http://localhost:8080/okta_api");
    }
    return originalFetch(resource, config);
  };
}

//pinia setup
const pinia = createPinia();

const showMaintenance = window.globalEnvVariables?.SHOW_MAINTENANCE;

//routing setup
export const router = createRouter({
  history: createWebHistory(),
  routes: showMaintenance ? maintenanceRoute : routes || [],
});
//handle redirect to login page if user is not authenticated
router.beforeEach(navigationGuard);

//okta setup
const oktaAuth = new OktaAuth(oktaConfig.oidc);
const oktaOptions: OktaVueOptions = {
  oktaAuth,
  onAuthRequired: () => {
    router.push(ROUTING.login.url);
  },
  onAuthResume: () => {
    router.push(ROUTING.login.url);
  },
};

createApp(App).use(pinia).use(router).use(OktaVue, oktaOptions).mount("#mercury-app");

// }
